.border-blue-light {
  border-color: #4b93ff;
}

.border-blue-dark {
  border-color: #3e59a5;
}

.border-purple-light {
  border-color: #a28bc0;
}

.border-green-light {
  border-color: #a0cf8b;
}

.border-yellow {
  border-color: $yellow;
}

// background
.bg-black {
  background-color: #262626;
}

.bg-white {
  background-color: #ffffff;
}

.bg-blue-light {
  background-color: #77adff;
}

.bg-yellow {
  background-color: #ffe26f;
}

.bg-green-saturated {
  background-color: #9de575;
}

.bg-green-light {
  background-color: #a1db81;
}

.bg-blue-gradient {
  background: -o-linear-gradient(top, #72aaff 0%, #458fff 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#72aaff),
    to(#458fff)
  );
  background: linear-gradient(180deg, #72aaff 0%, #458fff 100%);
}

.bg-purple-gradient {
  background: -o-linear-gradient(top, #ac8ee6 0%, #976ee6 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ac8ee6),
    to(#976ee6)
  );
  background: linear-gradient(180deg, #ac8ee6 0%, #976ee6 100%);
}

a,
button {
  &.bg-purple-gradient {
    opacity: 0.9;
    background: linear-gradient(180deg, #976ee6 0%, #7a47dd 100%);
    transition: opacity 0.3s linear;

    &:hover {
      opacity: 1;
    }
  }
}

.bg-blob-purple {
  background-image: url("/src/assets/svg/blob-purple.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-blob-blue {
  background-image: url("/src/assets/svg/blob-blue.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.box-shadow {
  -webkit-box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
}

.box-shadow-btn {
  -webkit-box-shadow: 4px 4px 13px rgba(0, 0, 0, 0.14);
  box-shadow: 4px 4px 13px rgba(0, 0, 0, 0.14);
}

.container {
  max-width: 1510px;
  padding-left: 50px;
  padding-right: 50px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 2.75rem;
  text-align: center;
}

.text-white {
  color: $white;
}

.text-black {
  color: $text;
}

.text-black-light {
  color: $head;
}

.text-head-1 {
  font-size: 3rem;
}

.text-head-2 {
  font-size: 1.5rem;
}

.text-description-1 {
  font-size: 1.125rem;
}

.fw-600 {
  font-weight: 600;
}

.fw-400 {
  font-weight: 400;
}

.text-center {
  text-align: center;
}

.radius-10 {
  border-radius: 10px;
}

.btn-md {
  font-family: "Montserrat", sans-serif;
  padding: 24px 48px;
  font-weight: 600;
  display: inline-block;
}

.bold {
  font-weight: 600;
}

@media only screen and (max-width: 1440px) {
  .text-head-1 {
    font-size: 2.5rem;
  }

  .text-head-2 {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 1360px) {
  .text-head-1 {
    font-size: 2.25rem;
  }

  .title {
    font-size: 2.25rem;
  }
}

@media only screen and (max-width: 1024px) {
  .text-head-2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .text-head-1,
  .title {
    font-size: 1.375rem;
  }

  .text-head-2 {
    font-size: 1.125rem;
  }

  .text-description-1 {
    font-size: 1rem;
  }

  .bg-blob-purple {
    background-image: url("/src/assets/svg/blob-purple-mobile.svg");
  }

  .bg-blob-blue {
    background-image: url("/src/assets/svg/blob-blue-mobile.svg");
  }
}

@media (max-width: 420px) {
  .btn-md {
    width: 100%;
  }
}
