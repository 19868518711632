@import "/src/styles/_reset";
@import "/src/styles/_variables";
@import "/src/styles/general.scss";
@import "/node_modules/swiper/swiper.scss";

html {
	scroll-behavior: smooth;
	max-width: 100vw;
	overflow-x: hidden;
}

body {
	color: $text;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	overflow-x: hidden;
	max-width: 100vw;
}

a {
	color: $text;
}

.bg__first-section {
	max-height: 850px;
	background-repeat: no-repeat;
	background-size: cover;
}

.navbar-container {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	max-width: 1510px;
	padding-left: 50px;
	padding-right: 50px;
	margin-bottom: 80px;

	.btn-tel {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 10px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding: 12px 24px;
		white-space: nowrap;

		& img {
			margin-right: 12px;
		}
	}
}

.navbar {
	& > * {
		transition: color 0.3s ease-in-out;

		&:hover {
			color: #4e4e4e;
		}

		&:not(:last-child) {
			margin-right: 20px;
		}
	}
}

// burger-menu

#burger-menu {
	cursor: pointer;
	height: 32px;
	width: 32px;
	overflow: visible;
	position: relative;
	display: none;
}

#burger-menu span,
#burger-menu span:before,
#burger-menu span:after {
	background: #263238;
	display: block;
	height: 4px;
	opacity: 1;
	position: absolute;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}

#burger-menu span:before,
#burger-menu span:after {
	content: "";
}
#burger-menu span {
	right: 0px;
	top: 13px;
	width: 32px;
}
#burger-menu span:before {
	left: 0px;
	top: -10px;
	width: 32px;
}
#burger-menu span:after {
	left: 0px;
	top: 10px;
	width: 32px;
}

#menu {
	z-index: 1;
	min-width: 100%;
	min-height: 100%;
	position: fixed;
	top: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	padding-top: 20px;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding-right: 50px;

	&.overlay {
		visibility: visible;
		opacity: 1;
		padding-top: 26px;
	}

	& ul {
		background-image: url("/src/assets/images/bg-menu.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-color: #9a72e6;
		padding: 28px 24px;
		-webkit-box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.12);
		box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.12);
		border-radius: 6px;
	}

	& .navbar__item {
		color: #fff;
		display: block;
		font-size: 1.125rem;
		margin-bottom: 28px;
		text-decoration: none;
	}
}

.burger-menu__social {
	& > * {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	& a {
		padding: 12px 16px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		color: #9a72e6;
		-webkit-box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12);
		box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12);
		border-radius: 7px;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	& svg {
		margin-right: 8px;
	}
}

.intro-section {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;

	padding-bottom: 110px;
}

.intro-section__content {
	max-width: 690px;
	width: 100%;
	margin-right: 40px;
}

.intro-section__title {
	position: relative;
	margin-bottom: 20px;
}

.intro-section__subtitle {
	border-radius: 7px;
	padding: 12px 24px;
	font-size: 1.75rem;
}

.intro-section__description {
	margin-bottom: 40px;
}

.intro-section__right {
	display: flex;
	flex-direction: column;
	align-items: center;

	& img {
		max-width: 560px;
		width: 100%;
		padding: 40px 20px 48px 20px;
	}
}

.about-us {
	padding-top: 80px;
	padding-bottom: 100px;

	& .title {
		margin-bottom: 60px;
	}
}

.about-us__list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 40px;
	-webkit-column-gap: 50px;
	-moz-column-gap: 50px;
	column-gap: 50px;
}

.about-us__list-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 48px;
	padding-right: 48px;
	min-height: 235px;
	border-bottom-width: 10px;
	border-style: solid;
	border-radius: 10px;

	& img {
		margin-right: 48px;
	}

	& h4 {
		margin-bottom: 12px;
	}
}

.for-whom {
	padding-top: 120px;
	padding-bottom: 120px;

	& .title {
		margin-bottom: 80px;
	}

	& > *:not(:last-child) {
		margin-bottom: 120px;
	}
}

.for-whom__list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 50px;
	margin-bottom: 60px;
}

.for-whom__list-item {
	padding: 28px 38px 36px 48px;
	border-right-width: 10px;
	border-style: solid;
	border-radius: 10px;

	& img {
		margin-bottom: 16px;
	}

	& h4 {
		margin-bottom: 12px;
	}
}

.for-whom__feedback {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 20px 24px;

	& img {
		width: 48px;
		margin-right: 16px;
	}
}

.results {
	padding-top: 120px;
	padding-bottom: 100px;

	& > div {
		& > * {
			&:not(:last-child) {
				margin-bottom: 80px;
			}
		}
	}

	a {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: fit-content;
	}
}

.our-teachers {
	padding-top: 120px;
	padding-bottom: 120px;

	& h2 {
		margin-bottom: 80px;
	}

	& > *:not(:last-child) {
		margin-bottom: 80px;
	}
}

.assymetric-block {
	display: flex;

	& > div {
		width: 100%;
		border-radius: 10px;
		padding: 48px 60px;
		max-width: 60%;

		& ul {
			list-style-type: disc;
			color: #ffffff;
			padding-left: 1em;

			& > *:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}

	& > picture {
		width: 100%;
		max-width: 40%;

		& img {
			border-radius: 10px;
			width: 100%;
		}
	}
}

.teacher-regalia__content {
	margin-right: 80px;
	background-color: #0bc2ac;

	& h3 {
		color: #fad517;
		margin-bottom: 20px;
		text-align: left;
	}
}

.teacher-education {
	& > picture {
		margin-right: 80px;
	}

	& > div {
		background-color: #68b55b;
	}
}

.students-achievements {
	& > div {
		background-color: #5a90ce;
		margin-right: 80px;
	}

	& p {
		color: #ffffff;
		font-size: 1.5rem;
		margin-bottom: 20px;
	}

	ul:not(:last-child) {
		margin-bottom: 20px;
	}
}

.test-results {
	overflow: hidden;

	& img {
		width: 100%;
	}

	& .swiper-slide {
		max-height: 640px;
	}
}

.lesson-cost {
	background-image: url("/src/assets/images/bg-sunrays-lesson-cost.png");
	background-repeat: no-repeat;
	padding-top: 120px;
	padding-bottom: 100px;

	& h2 {
		margin-bottom: 80px;
	}
}

.lesson-cost__list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 100px;

	& > :first-child {
		margin-right: 60px;
	}
}

.lesson-cost__list-item {
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
	padding: 40px 38px 40px 48px;
	position: relative;
	border-right-width: 10px;
	border-style: solid;

	& h3 {
		font-size: 2rem;
		margin-bottom: 32px;
		color: #3c464b;
	}

	& h4 {
		margin-bottom: 28px;
	}

	& ul {
		font-size: 1.5rem;

		&:not(:last-child) {
			margin-bottom: 32px;
		}

		& > * {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}
}

.lesson-price {
	text-align: center;

	& > * {
		&:not(:last-child) {
			margin-bottom: 32px;
		}
	}
}

.old-price {
	color: #262626;
	position: relative;
	font-size: 2rem;
	max-width: 205px;
	margin-left: auto;
	margin-right: auto;

	&::after {
		position: absolute;
		right: 0;
		top: 50%;
		content: "";
		width: 100%;
		height: 4px;
		background-color: #bf4040;
	}
}

.new-price {
	font-size: 1.75rem;
	background-color: #72aaff;
	padding-top: 6px;
	border-radius: 24px;
	padding-bottom: 6px;
}

.lesson-price__cta {
	border-radius: 24px;
	display: block;
	padding-top: 8px;
	padding-bottom: 8px;
}

.footer__navbar-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 28px;
	padding-bottom: 28px;
}

.footer__navbar {
	& > * {
		&:not(:last-child) {
			margin-right: 36px;
		}
	}
}

.social-network {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;

	& > * {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;

		&:not(:last-child) {
			margin-right: 20px;
		}
	}
}

.social-network__instagram,
.social-network__telegram {
	padding: 12px;
}

.social-network__text {
	display: none;
}

.social-network__whatsapp {
	padding: 12px 24px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	& img {
		margin-right: 12px;
	}
}

.footer__copyright {
	padding-top: 12px;
	padding-bottom: 12px;
}

.button {
	background: #428bca;
	padding: 1em 2em;
	color: #fff;
	border: 0;
	border-radius: 5px;
	cursor: pointer;
}

.button:hover {
	background: #3876ac;
}

.about-platform__video {
	width: 100%;
	aspect-ratio: 16 / 9;
}

@media (max-width: 1440px) {
	.intro-section__subtitle {
		font-size: 1.5rem;
	}

	.intro-section__content {
		max-width: 580px;
	}
}

@media (max-width: 1360px) {
	.intro-section__content {
		max-width: 460px;
	}

	.about-us__list-item {
		padding: 24px 36px;
	}

	.for-whom__list {
		gap: 32px;
	}

	.for-whom__list-item {
		padding-left: 32px;
		padding-right: 22px;
	}

	.footer__navbar {
		& > * {
			&:not(:last-child) {
				margin-right: 16px;
			}
		}
	}
}

@media (max-width: 1260px) {
	.navbar-container {
		margin-bottom: 24px;
	}

	.navbar {
		& > * {
			&:not(:last-child) {
				margin-right: 8px;
			}
		}
	}

	.navbar__item {
		font-size: 1rem;
	}

	.social-network {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		& > * {
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			padding: 12px 24px;

			&:not(:last-child) {
				margin-right: 0;
				margin-bottom: 20px;
			}
		}

		img {
			margin-right: 12px;
		}
	}

	.social-network__text {
		display: block;
	}
}

@media (max-width: 1120px) {
	.navbar-container {
		.btn-tel {
			font-size: 1rem;
			img {
				margin-right: 4px;
			}
		}
	}
}

@media (max-width: 1100px) {
	.navbar__item {
		font-size: 0.875rem;
	}

	.intro-section__content {
		max-width: 400px;
	}
}

@media (max-width: 1024px) {
	.bg__first-section {
		max-height: unset;
	}

	.navbar {
		display: none;
	}

	#burger-menu {
		display: block;
		-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
		order: 3;
		margin-right: 0;
	}

	.navbar-container {
		margin-bottom: 15px;
	}

	.intro-section {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
		padding-bottom: 84px;
	}

	.intro-section__content {
		-ms-flex-item-align: start;
		align-self: flex-start;
		max-width: 593px;
	}

	.intro-section__title {
		margin-bottom: 12px;
	}

	.intro-section__subtitle {
		margin-bottom: 20px;
	}

	.intro-section__description {
		padding-bottom: 0;
		margin-bottom: 28px;
	}

	.about-us {
		padding-bottom: 60px;

		& .title {
			margin-bottom: 40px;
		}
	}

	.about-us__list {
		grid-template-columns: 1fr;
		gap: 28px;
	}

	.about-us__list-item {
		& h4 {
			margin-bottom: 12px;
		}

		& img {
			width: 80px;
		}
	}

	.for-whom,
	.results,
	.lesson-cost {
		padding-top: 60px;
		padding-bottom: 60px;

		& .title {
			margin-bottom: 40px;
		}
	}

	.for-whom {
		& > *:not(:last-child) {
			margin-bottom: 80px;
		}
	}

	.for-whom__list {
		grid-template-columns: 1fr 1fr;
		margin-bottom: 40px;
	}

	.for-whom__feedback {
		& .text-head-2 {
			font-size: 1.25rem;
		}
	}

	.results > div > :not(:last-child) {
		margin-bottom: 40px;
	}

	.our-teachers {
		padding-top: 80px;
		padding-bottom: 80px;

		& h2,
		& > *:not(:last-child) {
			margin-bottom: 40px;
		}
	}

	.assymetric-block {
		flex-direction: column;

		& > div {
			order: 1;
			padding: 24px 32px;
			max-width: unset;

			& ul {
				padding-left: 1em;
			}
		}

		& > picture {
			max-width: 500px;
			margin-left: auto;
			margin-bottom: 40px;
			margin-right: auto;
		}
	}

	.teacher-regalia__content {
		order: 1;
		margin-right: 0;

		& + picture {
			max-width: 400px;
		}
	}

	.students-achievements {
		& > div {
			margin-left: 0;
		}
	}

	.lesson-cost {
		& h2 {
			margin-bottom: 40px;
		}
	}

	.lesson-cost__list {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-bottom: 40px;

		& > :first-child {
			margin-right: 0;
			margin-bottom: 40px;
		}
	}

	.footer__navbar-container {
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;

		& > * {
			&:not(:last-child) {
				margin-right: 8px;
			}
		}
	}

	.footer__navbar {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;

		& > * {
			&:not(:last-child) {
				margin-right: 0px;
				margin-bottom: 28px;
			}
		}
	}

	.navbar__item {
		font-size: 1.125rem;
	}
}

@media (max-width: 576px) {
	.mobile-text-hide {
		display: none;
	}

	#menu {
		padding-right: 16px;
	}

	.navbar-container,
	.intro-section {
		padding-left: 16px;
		padding-right: 16px;
	}

	.navbar-container {
		margin-bottom: 0;

		& .btn-tel {
			display: none;
		}
	}

	.intro-section__right img {
		padding-top: 16px;
		padding-bottom: 24px;
	}

	.intro-section__subtitle {
		font-size: 1rem;
	}

	.intro-section {
		padding-bottom: 48px;
	}

	.about-us,
	.for-whom,
	.results,
	.lesson-cost {
		padding-top: 48px;
		padding-bottom: 48px;

		& .title {
			margin-bottom: 32px;
		}
	}

	.for-whom {
		& > *:not(:last-child) {
			margin-bottom: 40px;
		}
	}

	.about-us__list {
		gap: 24px;
	}

	.about-us__list-item {
		padding: 28px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;

		& img {
			margin-right: 0;
			width: 64px;
			margin-bottom: 16px;
		}
	}

	.for-whom__list {
		grid-template-columns: 1fr;
	}

	.for-whom__list-item {
		padding-left: 28px;
		padding-right: 18px;
		& img {
			height: 64px;
		}
	}

	.for-whom__feedback .text-head-2 {
		font-size: 1rem;
	}

	.lesson-cost {
		& h2 {
			margin-bottom: 36px;
		}
	}

	.lesson-cost__list {
		margin-bottom: 36px;
		& > :first-child {
			margin-bottom: 32px;
		}
	}

	.lesson-cost__list-item {
		padding: 28px 18px 28px 28px;

		& h4 {
			font-size: 1.25rem;
			margin-bottom: 24px;
		}

		& ul {
			font-size: 1rem;

			& > * {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
				&:not(:last-child) {
					margin-bottom: 20px;
				}

				& > :last-child {
					text-align: right;
				}
			}
		}
	}

	.our-teachers {
		padding-top: 40px;
		padding-bottom: 40px;

		& h2,
		& > *:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	.assymetric-block {
		& > div {
			padding: 16px 24px;
		}

		& > picture {
			max-width: 320px;
			margin-bottom: 20px;
		}
	}

	.teacher-regalia__content + picture {
		max-width: 320px;
	}

	.footer__navbar-container {
		display: grid;
		grid-template-areas:
			"social social"
			"navbar logo";
		padding-top: 32px;
		padding-bottom: 32px;
		row-gap: 32px;

		& > * {
			&:not(:last-child) {
				margin-right: 0;
			}
		}
	}

	.footer__logo {
		grid-area: logo;
		margin-left: auto;
	}

	.footer__navbar {
		grid-area: navbar;

		& > * {
			&:not(:last-child) {
				margin-bottom: 20;
			}
		}
	}

	.social-network {
		grid-area: social;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;

		& > * {
			&:not(:last-child) {
				margin-bottom: 0;
				margin-left: 16px;
			}
		}

		& img {
			margin-right: 0;
			width: 24px;
			height: 24px;
		}
	}

	.social-network__whatsapp {
		& img {
			margin-right: 8px;
		}
	}

	.footer__navbar-container .social-network__text {
		display: none;
	}

	.social-network__instagram,
	.social-network__telegram {
		padding: 12px;
	}

	.social-network__whatsapp {
		padding-left: 16px;
		padding-right: 16px;
	}

	.footer__copyright {
		font-size: 0.75rem;
		padding-left: 40px;
		padding-right: 40px;
	}
}

@media (max-width: 420px) {
	.social-network {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;

		& > * {
			&:not(:last-child) {
				margin-left: 0;
			}
		}
	}
}
